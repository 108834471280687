import React from "react";

const user_init = {
    userData : "", 
    setUserData : (data) => {}
}

const TeamContext = React.createContext(user_init)

class TeamContextProvider extends React.Component {
    state = {
        userData: "",
    }
    setUserData = (data) => {
        localStorage.setItem("userData", data);
        // let userData = localStorage.getItem("userData")
        this.setState({ 
            userData : data 
        })
    }
    componentDidMount() {
        let userData = localStorage.getItem("userData")
        this.setState({ userData })
    }
    render() {
      const { children } = this.props
      const { userData } = this.state
      return (
        <TeamContext.Provider
          value={{
            userData,
            setUserData: this.setUserData,
          }}
        >
          {children}
        </TeamContext.Provider>
      )
    }
}

export default TeamContext

export {TeamContextProvider};